<template>
  <div>
    <div class="scientificPublication" v-if="!blockEditingInProcess">
      <div
        :style="!isExpert && !isManager ?
        'position: absolute; top: 18px; right: 14px' :'position: absolute; top: 10px; right: 14px'">
        <!--        v-if="konvertConvert && (isExpert || isManager)"-->
        <button
          class="usual-btn" style="padding: 5px 34px;" @click="editRowExpert"
          v-if="konvertConvert && isManager"
        >
          Балл
        </button>
        <button class="usual-btn usual-btn_disabled" v-else-if="applicantBlocked && (!isExpert || isManager)">Изменить</button>
        <button class="usual-btn" @click="editRow" v-else-if="!applicantBlocked && (!isExpert || isManager)">Изменить</button>
        <button class="usual-btn" @click="cancelEditRow" v-else-if="(!isExpert || isManager)">Отменить</button>
        <v-dialog
          v-model="deleteConfirmDialog"
          width="375"
        >
          <v-card>
            <v-card-title>Подтверждение удаления строки</v-card-title>
            <v-card-actions style="justify-content: center">
              <v-btn class="dialog-btn" @click="deleteRow">Да</v-btn>
              <v-btn class="dialog-btn" @click="deleteConfirmDialog = false">Нет</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="curatorBlockEditingInProcess"
          width="500"
        >
          <v-card>
            <v-card-title>Введите балл</v-card-title>
            <v-card-actions style="justify-content: center">
              <div class="expertBall">
                <div class="inputDiv">
                  <label>Балл</label>
                  <v-text-field
                    v-model="value.ballBallExpert"
                  ></v-text-field>
                </div>
                <p class="bold" style="margin: 15px auto 0 5px">Комментарий</p>
                <textarea
                  class="textarea"
                  v-model="value.ballCommentExpert"
                ></textarea>
                <v-btn class="dialog-btn" style="margin-top: 25px; margin-bottom: 15px;"
                       @click="expertBallUpdate(value.id, value.ballBallExpert, value.ballCommentExpert)">Сохранить
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div v-if="konvertConvert && (isExpert || isManager)" class="applicantsSelectionJsonOrgScore">
          <p style="margin-top: 3px;" v-if="(value.ballBallMethod + value.ballBallExpert > 0) && (!isExpert || isManager)">
            {{ value.ballBallMethod }}<span style="font-size: 14px; line-height: 16px;">методика</span></p>
          <p style="margin-top: 3px;" v-if="value.ballBallMethod + value.ballBallExpert > 0">
            {{ value.ballBallExpert }}<span style="font-size: 14px; line-height: 16px;">комиссия</span>
            <span v-if="value.ballCommentExpert && value.ballCommentExpert !== 'null'"
                  :data-tooltip="`${value.ballCommentExpert}`"
                  class="expertBall-info"></span>
          </p>
        </div>
      </div>
      <div class="textInfoDiv">
        <span class="order">{{ order + 1 }}</span>
        <p class="type">{{ itemType }}</p>
      </div>
      <p class="name" v-html="value.eventName"></p>
      <!--      <p class="authors" v-html="value.patentAuthors"></p>-->
      <div class="textInfoDiv">
        <p class="text">{{ itemProofDoc }}</p>
        <p class="text" style="margin-right: 40px;">{{ itemPlace }}</p>
      </div>
      <div class="textInfoDiv">
        <p class="name" style="margin-right: 20px; margin-top: 0;" v-html="value.eventDocument"></p>
        <div
          class="scan" v-if="value.sysScanFileUuid"
          @mouseout="hover.scan = false" @mouseover="hover.scan = true">
          <img v-if="hover.scan" src="@/assets/icons/stp_scan_orange.svg" class="scan__img" alt="scan">
          <img v-else src="@/assets/icons/stp_scan_blue.svg" class="scan__img" alt="scan">
          <p
            class="scan__text"
            @click="getFile(value.sysScanFileUuid, value.sysScanFileNameOrig, value.sysScanFileExt)"
          >
            Скан документа
          </p>
        </div>
      </div>
    </div>
    <div v-if="blockEditingInProcess">
      <Block5Creation
        :rowID="rowID"
        :block5Data="value"
        @updateData="updateData"
      />
    </div>
  </div>
</template>

<script>
import Block5Creation from "@/components/applicants/applicantForm/korolevBlocks/blockCreation/Block5Creation";
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "Block5Item",
  components: {Block5Creation},
  data() {
    return {
      blockEditingInProcess: false,
      curatorBlockEditingInProcess: false,
      // expertBall: null,
      // expertComment: null,
      deleteConfirmDialog: false,
      hover: {
        scan: false,
        url: false,
      },
    }
  },
  props: {
    value: {
      type: Object,
      default: function () {
        return {}
      },
    },
    eventTypeArray: {
      type: Array,
      default: function () {
        return []
      },
    },
    eventPrizePlaceArray: {
      type: Array,
      default: function () {
        return []
      },
    },
    statusArray: {
      type: Array,
      default: function () {
        return []
      },
    },
    rowID: {
      type: String,
      default: ''
    },
    order: {
      type: Number,
      default: 0
    },
    applicantBlocked: {
      type: Boolean,
      default: false,
    },
    konvertConvert: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      isExpert: 'isExpert',
      // isCurator: 'isCurator',
      isManager: 'isManager'
    }),

    itemType() {
      return `${this.eventTypeArray.filter(el => el.value === this.value.eventType.id)[0]?.text} /
      ${this.statusArray.filter(el => el.value === this.value.eventStatus.id)[0]?.text}`
    },

    itemProofDoc() {
      return `⚑ ${this.eventPrizePlaceArray.filter(el => el.value === this.value.eventPrizePlace.id)[0]?.text},
      ${this.value.hasCoauthor ? 'Командная победа' : 'Личная победа'}`
    },

    itemPlace() {
      return `◉ ${this.value.eventPlace}, ${this.value.eventStartDate} - ${this.value.eventEndDate}`
    },
  },
  methods: {
    editRow() {
      this.blockEditingInProcess = true
    },

    editRowExpert() {
      this.curatorBlockEditingInProcess = true
    },

    cancelEditRow() {
      this.blockEditingInProcess = false;
      this.$emit("updateData");
    },

    updateData() { // вызов getAll в корневом файле
      this.$emit("updateData");
    },

    deleteConfirmFunc() {
      this.deleteConfirmDialog = true;
    },

    async deleteRow() {
      try {
        const res = await axios.delete(`/api/2022/korolev/block5/delete?rowId=${this.value.id}`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          console.log('deleteRow успех');
          this.$emit("updateData");
        }
      } catch (e) {
        console.log(e);
      }
    },

    async expertBallUpdate(rowId, ball, comment) {
      let url = `/api/2022/korolev/expertBall/block5`;
      const formData = new FormData();
      formData.append('rowId', rowId);
      formData.append('ball', ball);
      formData.append('comment', comment);
      try {
        const res = await axios.post(url, formData,
          {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          this.value.ballBallExpert = ball;
          this.value.ballCommentExpert = comment;
          // this.$emit("updateData"); // вызов getAll в корневом файле
          this.curatorBlockEditingInProcess = false;
          console.log('expertBallUpdate успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async getFile(id, name, ext) {
      let uri = `/api/2022/korolev/blocks/scan/download?sysScanFileUuid=${id}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`}
      })
      this.saveByteArray(`${name}.${ext}`, responseAxios.data, ext)
    },

    saveByteArray(reportName, byte, ext) {
      let blob = new Blob([byte], {type: `application/${ext}`});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank'; // вариант открытия в новом окне
      // link.download = reportName; // вариант скачивания
      link.click();
    },
  }
}
</script>

<style scoped>

</style>
