<template>
  <div v-if="rowID !== '0'">
    <div v-if="loading" style="min-height: 200px;" class="d-flex flex-row align-center">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <div class="blocks" v-else>
      <div class="blocks__headerDiv" style="display: flex; flex-direction: row"
           :style="!blockOpened ? 'border-bottom: none' : ''" @click="blockOpened = !blockOpened">
        <p class="headerText">Блок 3. Охранные документы (открытия, патенты, свидетельства)</p>
        <img
          v-if="blockOpened"
          src="@/assets/icons/block_opened_blue.svg" class="btn_open" alt="">
        <img
          v-else
          src="@/assets/icons/block_closed_blue.svg" class="btn_open" alt="">
      </div>
      <div v-if="blockOpened && block3Data.length > 0">
        <Block3Item
          v-for="(item, j) in block3Data" :key="j"
          :value.sync="item"
          :rowID="rowID"
          :konvertConvert="konvertConvert"
          :order="j"
          :applicantBlocked="applicantBlocked"
          :docTypeArray="docTypeArray"
          :docSubTypeArray="docSubTypeArray"
          @updateData="getAllBlock3"
        />
      </div>
      <Block3Creation
        v-if="blockOpened && blockEditingInProcess && !applicantBlocked"
        :applicantBlocked="applicantBlocked"
        :rowID="rowID"
        @updateData="getAllBlock3"
      />
      <div class="btnDiv" v-if="blockOpened && block3Data.length > 0">
        <button v-if="applicantBlocked || isExpert" class="usual-btn usual-btn_disabled">Добавить</button>
        <button v-else class="usual-btn" @click="addRow">Добавить</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";
import Block3Item from "@/components/applicants/applicantForm/korolevBlocks/blockItems/Block3Item";
import Block3Creation from "@/components/applicants/applicantForm/korolevBlocks/blockCreation/Block3Creation";

export default {
  name: "Block3",
  components: {Block3Creation, Block3Item},
  data() {
    return {
      loading: false,
      blockOpened: false,
      blockEditingInProcess: true,
      block3Data: [],
      docTypeArray: [],
      docSubTypeArray: [],
    }
  },
  props: {
    rowID: {
      type: String,
      default: ''
    },
    applicantBlocked: {
      type: Boolean,
      default: false,
    },
    konvertConvert: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      isExpert: 'isExpert',
      // isCurator: 'isCurator',
    }),
  },
  created() {
    if (this.rowID !== '0') {
      this.getAllBlock3();
      this.getSprDocType();
      this.getSprDocSubType();
    }
    
    if (this.isExpert) {
      this.blockOpened = true;
    }
  },
  methods: {
    async getAllBlock3() {
      this.loading = true;
      this.block3Data = [];
      try {
        const res = await axios.get(`/api/2022/korolev/block3/get?mainId=${this.rowID}`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          if (res.data.length > 0) {
            this.block3Data = res.data;
            this.blockEditingInProcess = false;
          } else {
            this.blockEditingInProcess = true;
          }
          console.log('getAllBlock3 успех');
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    
    async getSprDocType() {
      try {
        const res = await axios.get(`/api/2022/spr/textVal/get?block=3&position=1`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.docTypeArray.push({
              text: res.data[i].value,
              value: res.data[i].id
            })
          }
          console.log('getSprDocType успех');
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    async getSprDocSubType() {
      try {
        const res = await axios.get(`/api/2022/spr/textVal/get?block=3&position=2`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.docSubTypeArray.push({
              text: res.data[i].value,
              value: res.data[i].id
            })
          }
          console.log('getSprDocSubType успех');
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    addRow() {
      this.blockEditingInProcess = true
    },
  }
}
</script>

<style lang="scss" scoped>

</style>