<template>
  <div v-if="rowID !== '0'">
    <div v-if="loading" style="min-height: 200px;" class="d-flex flex-row align-center">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <div class="blocks" v-else>
      <div class="blocks__headerDiv" style="display: flex; flex-direction: row"
           :style="!blockOpened ? 'border-bottom: none' : ''" @click="blockOpened = !blockOpened">
        <p class="headerText">Блок 2. Патенты на изобретение</p>
        <img
          v-if="blockOpened"
          src="@/assets/icons/block_opened_blue.svg" class="btn_open" alt="">
        <img
          v-else
          src="@/assets/icons/block_closed_blue.svg" class="btn_open" alt="">
      </div>
      <div v-if="blockOpened && block2Data.length > 0">
        <Block2Item
          v-for="(item, j) in block2Data" :key="j"
          :value.sync="item"
          :rowID="rowID"
          :konvertConvert="konvertConvert"
          :order="j"
          :applicantBlocked="applicantBlocked"
          :patentTypeArray="patentTypeArray"
          @updateData="getAllBlock2"
        />
      </div>
      <Block2Creation
        v-if="blockOpened && blockEditingInProcess && !applicantBlocked"
        :applicantBlocked="applicantBlocked"
        :rowID="rowID"
        @updateData="getAllBlock2"
      />
      <div class="btnDiv" v-if="blockOpened && block2Data.length > 0">
        <button v-if="applicantBlocked || isExpert" class="usual-btn usual-btn_disabled">Добавить</button>
        <button v-else class="usual-btn" @click="addRow">Добавить</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";
import Block2Item from "@/components/applicants/applicantForm/korolevBlocks/blockItems/Block2Item";
import Block2Creation from "@/components/applicants/applicantForm/korolevBlocks/blockCreation/Block2Creation";

export default {
  name: "Block2",
  components: {Block2Creation, Block2Item},
  data() {
    return {
      loading: false,
      blockOpened: false,
      blockEditingInProcess: true,
      block2Data: [],
      patentTypeArray: [],
    }
  },
  props: {
    rowID: {
      type: String,
      default: ''
    },
    applicantBlocked: {
      type: Boolean,
      default: false,
    },
    konvertConvert: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      isExpert: 'isExpert',
      // isCurator: 'isCurator',
    }),
  },
  created() {
    if (this.rowID !== '0') {
      this.getAllBlock2();
      this.getSprPatentType();
    }
    
    if (this.isExpert) {
      this.blockOpened = true;
    }
  },
  methods: {
    async getAllBlock2() {
      this.loading = true;
      this.block2Data = [];
      try {
        const res = await axios.get(`/api/2022/korolev/block2/get?mainId=${this.rowID}`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          if (res.data.length > 0) {
            this.block2Data = res.data;
            this.blockEditingInProcess = false;
          } else {
            this.blockEditingInProcess = true;
          }
          console.log('getAllBlock2 успех');
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    async getSprPatentType() {
      try {
        const res = await axios.get(`/api/2022/spr/textVal/get?block=2&position=1`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.patentTypeArray.push({
              text: res.data[i].value,
              value: res.data[i].id
            })
          }
          console.log('getSprPatentType успех');
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    addRow() {
      this.blockEditingInProcess = true
    },
  }
}
</script>

<style lang="scss" scoped>

</style>