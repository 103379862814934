<template>
  <div v-if="loading" style="min-height: 200px" class="d-flex flex-row align-center">
    <v-progress-linear indeterminate></v-progress-linear>
  </div>
  <div v-else class="personInfoBlock">
    <div class="personInfo__headerDiv" style="display: flex; flex-direction: row">
      <p class="personInfo__header">Информация о претенденте на стипендию</p>
      <img
        v-if="blockOpened"
        @click="blockOpened = !blockOpened"
        src="@/assets/icons/block_opened_blue.svg"
        class="btn_open"
        alt="" />
      <img
        v-else
        @click="blockOpened = !blockOpened"
        src="@/assets/icons/block_closed_blue.svg"
        class="btn_open"
        alt="" />
    </div>
    <div class="fioBlock">
      <div class="inputDiv">
        <label>Фамилия <span style="color: red">*</span></label>
        <v-text-field
          :rules="[rules.required]"
          v-model="form.mainLastName"
          :disabled="!editionMode"
          :style="editionMode ? 'background: #E3EFFA' : ''">
        </v-text-field>
      </div>
      <div class="inputDiv">
        <label>Имя <span style="color: red">*</span></label>
        <v-text-field
          :rules="[rules.required]"
          v-model="form.mainFirstName"
          :disabled="!editionMode"
          :style="editionMode ? 'background: #E3EFFA' : ''"></v-text-field>
      </div>
      <div class="inputDiv">
        <label>Отчество (при наличии)</label>
        <v-text-field
          v-model="form.mainSecondName"
          :disabled="!editionMode"
          :style="editionMode ? 'background: #E3EFFA' : ''"></v-text-field>
      </div>
      <div class="inputDiv" style="width: 170px; margin-right: 0">
        <label>Пол <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!editionMode"
          :style="editionMode ? 'background: #E3EFFA' : ''"
          v-model="form.mainGender"
          :items="gendersArray"></v-autocomplete>
      </div>
      <div class="inputDiv" style="width: 1145px; margin-right: 0">
        <div class="scientificPublication__row" style="margin-bottom: -10px; margin-top: -10px">
          <v-checkbox :disabled="!editionMode" v-model="prevFio">
            <template v-slot:label>
              <div>Изменялись ли за последние 2 года фамилия/имя/отчество претендента?</div>
            </template>
          </v-checkbox>
        </div>
      </div>
      <div class="inputDiv" v-if="prevFio">
        <label>Прежняя фамилия</label>
        <v-text-field
          v-model="form.mainEdit2022OldLastName"
          :disabled="!editionMode"
          :style="editionMode ? 'background: #E3EFFA' : ''"></v-text-field>
      </div>
      <div class="inputDiv" v-if="prevFio">
        <label>Прежнее имя</label>
        <v-text-field
          v-model="form.mainEdit2022OldFirstName"
          :disabled="!editionMode"
          :style="editionMode ? 'background: #E3EFFA' : ''"></v-text-field>
      </div>
      <div class="inputDiv" v-if="prevFio">
        <label>Прежнее отчество (при наличии)</label>
        <v-text-field
          v-model="form.mainEdit2022OldSecondName"
          :disabled="!editionMode"
          :style="editionMode ? 'background: #E3EFFA' : ''"></v-text-field>
      </div>
    </div>
    <div class="citizenship" v-if="blockOpened">
      <div class="inputDiv">
        <label>Гражданство <span style="color: red">*</span></label>
        <v-autocomplete
          :disabled="!editionMode"
          :style="editionMode ? 'background: #E3EFFA' : ''"
          v-model="form.mainEdit2022IsForeign"
          :items="citizenshipArray"></v-autocomplete>
      </div>
      <div class="inputDiv">
        <label>Электронная почта <span style="color: red">*</span></label>
        <v-text-field
          :rules="[rules.required, rules.email]"
          v-model="form.mainEmail"
          :disabled="!editionMode"
          :style="editionMode ? 'background: #E3EFFA' : ''"></v-text-field>
      </div>
      <div class="inputDiv">
        <label>Мобильный телефон <span style="color: red">*</span></label>
        <v-text-field
          :rules="[rules.required]"
          v-model="form.mainPhoneNumber"
          :disabled="!editionMode"
          :style="editionMode ? 'background: #E3EFFA' : ''"></v-text-field>
      </div>
    </div>
    <div class="university" v-if="blockOpened">
      <p class="bold" style="margin: 13px auto 0 5px">
        Сведения на момент подачи заявки (на 2022/23 уч. год)
      </p>
      <div style="display: flex; flex-direction: row">
        <div class="inputDiv">
          <label>Уровень подготовки <span style="color: red">*</span></label>
          <v-autocomplete
            :rules="[rules.required]"
            :disabled="!editionMode"
            :style="editionMode ? 'background: #E3EFFA' : ''"
            v-model="form.mainSpec.usl.id"
            :items="spr.usl"
            @change="getSprSpec('edit')"></v-autocomplete>
          <!--        form.mainSpec.usl.id !== 6-->
        </div>
        <div class="inputDiv" style="width: 490px">
          <label>Специальность (Код, наименование) <span style="color: red">*</span></label>
          <v-autocomplete
            :rules="[rules.required]"
            :disabled="!editionMode || !form.mainSpec.usl.id"
            :style="editionMode ? 'background: #E3EFFA' : ''"
            v-model="form.mainSpec.id"
            :items="spr.spec"></v-autocomplete>
        </div>
        <div class="inputDiv" style="margin-right: 0">
          <label>Курс/год обучения <span style="color: red">*</span></label>
          <v-autocomplete
            :rules="[rules.required]"
            :disabled="!editionMode || !form.mainSpec.usl.id"
            :style="editionMode ? 'background: #E3EFFA' : ''"
            v-model="form.mainCourse"
            :items="coursesArrayFiltered"></v-autocomplete>
        </div>
      </div>
      <div class="inputDiv">
        <label>Статус обучающегося <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!editionMode || !form.mainSpec.usl.id"
          :style="editionMode ? 'background: #E3EFFA' : ''"
          v-model="form.mainEducationStatus.id"
          :items="spr.educationStatus"></v-autocomplete>
      </div>
      <!--      :disabled="!blockEditingInProcessComputed || !form.mainStpType.id"-->
      <div class="inputDiv">
        <label>Форма обучения <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!editionMode"
          :style="editionMode ? 'background: #E3EFFA' : ''"
          v-model="form.mainFo.id"
          :items="spr.fo"></v-autocomplete>
      </div>
      <div class="inputDiv" style="width: 490px; margin-right: 0">
        <label>Форма финансирования <span style="color: red">*</span></label>
        <v-autocomplete
          :rules="[rules.required]"
          :disabled="!editionMode"
          :style="editionMode ? 'background: #E3EFFA' : ''"
          v-model="form.mainFf.id"
          :items="spr.ff"></v-autocomplete>
      </div>
    </div>
    <v-dialog v-model="invalidData" :width="errorWidth">
      <v-card>
        <v-card-title style="margin: 0 auto">
          {{ errorText }}
        </v-card-title>
      </v-card>
    </v-dialog>
    <div class="rating" v-if="blockOpened">
      <p class="bold" v-if="form.mainCourse && form.mainCourse !== 1">
        Сведения об оценках претендента (за 2 предыдущие промежуточные сессии)
      </p>
      <p class="bold" v-if="form.mainCourse && form.mainCourse === 1">
        Сведения об оценках претендента за промежуточную зимнюю сессию и оценки государственной
        итоговой аттестации предыдущего уровня подготовки (государственный экзамен и (или) защита
        выпускной квалификационной работы)
      </p>
      <!--      v-if="form.mainSpec.usl.id !== 6"-->
      <div style="display: flex; flex-direction: row">
        <div class="inputDiv">
          <label>Общее («зачтено» не учитывается) <span style="color: red">*</span></label>
          <v-text-field
            type="number"
            :disabled="!editionMode"
            v-model="form.mainScoreCount"
            :style="editionMode ? 'background: #E3EFFA' : ''"></v-text-field>
        </div>
        <div class="inputDiv">
          <label>Оценки «Отлично» <span style="color: red">*</span></label>
          <v-text-field
            type="number"
            :disabled="!editionMode"
            v-model="form.mainScore5Count"
            :style="editionMode ? 'background: #E3EFFA' : ''"></v-text-field>
        </div>
        <div class="inputDiv">
          <label>Оценки «Хорошо» <span style="color: red">*</span></label>
          <v-text-field
            type="number"
            :disabled="!editionMode"
            v-model="form.mainScore4Count"
            :style="editionMode ? 'background: #E3EFFA' : ''"></v-text-field>
        </div>
      </div>
      <!--      v-if="form.mainSpec.usl.id !== 6"-->
      <div style="display: flex; flex-direction: row">
        <div class="inputDiv" style="width: 625px; margin-right: 0">
          <label
            >Наличие академической задолженности за весь период обучения
            <span style="color: red">*</span></label
          >
          <v-autocomplete
            :rules="[rules.required]"
            :disabled="!editionMode"
            :style="editionMode ? 'background: #E3EFFA' : ''"
            v-model="academicDebt"
            :items="academicDebtArray"></v-autocomplete>
        </div>
      </div>
    </div>
    <div class="btnDiv" v-if="blockOpened">
      <!--  && (!isExpert || isManager)-->
      <button
        v-if="blockEditingInProcessComputed && !editionMode"
        class="usual-btn"
        @click="editionMode = true">
        Изменить
      </button>
      <button
        v-else-if="blockEditingInProcessComputed && editionMode"
        class="usual-btn"
        @click="sendInfo">
        Сохранить
      </button>
      <button v-else class="usual-btn usual-btn_disabled">Изменить</button>
      <p class="block__hint">
        <span style="color: red">*</span> Все поля, помеченные звездочкой, обязательны для
        заполнения
      </p>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import { mapGetters } from 'vuex'; // mapActions

  export default {
    name: 'PersonInfoBlock',
    data() {
      return {
        spr: {
          ff: [],
          fo: [],
          spec: [],
          usl: [],
          educationStatus: [],
        },
        // orgType: null,
        invalidData: false,
        loading: false,
        blockOpened: false,
        editionMode: false,
        academicDebt: 0,
        prevFio: false,
        form: {
          id: 0,
          mainFirstName: '',
          mainLastName: '',
          mainSecondName: '',
          mainGender: '',
          mainEdit2022OldLastName: '',
          mainEdit2022OldFirstName: '',
          mainEdit2022OldSecondName: '',
          mainEmail: '',
          mainPhoneNumber: '',
          orgName: '',
          mainCourse: '',
          mainAspScoreSpeciality: '',
          mainAspScorePhilosophy: '',
          mainAspScoreForeignLang: '',
          mainAspWorkDoneVolume: '',
          mainAspDisserTheme: '',
          mainSpec: { id: '', usl: { id: '' } },
          mainEdit2022IsForeign: null,
          mainEducationStatus: { id: '' },
          gradebookNumber: '',
          mainFo: { id: '' },
          mainFf: { id: '' },
          mainScoreCount: 0,
          mainScore4Count: 0,
          mainScore5Count: 0,
        },
        gendersArray: [
          { text: 'Мужской', value: 'MALE' },
          { text: 'Женский', value: 'FEMALE' },
        ],
        citizenshipArray: [
          { text: 'Российская Федерация', value: false },
          { text: 'Иностранное', value: true },
        ],
        coursesArray: [
          { text: '1 курс', value: 1 },
          { text: '2 курс', value: 2 },
          { text: '3 курс', value: 3 },
          { text: '4 курс', value: 4 },
          { text: '5 курс', value: 5 },
          { text: '6 курс', value: 6 },
        ],
        coursesArrayFiltered: [],
        aspMarksArray: [
          { text: 'Отлично', value: 5 },
          { text: 'Хорошо', value: 4 },
          { text: 'Удовлетворительно', value: 3 },
          // {text: 'Не сдан', value: 2},
          { text: 'Не подошел срок сдачи экзамена', value: 1 },
        ],
        academicDebtArray: [
          { text: 'Да', value: 2 },
          { text: 'Нет', value: 1 },
        ],
        rules: {
          required: (value) => !!value || 'Обязательное поле!',
          nonNegativeFloat: (value) => value >= 0 || '*',
          email: (value) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || 'Неверно указан адрес почты';
          },
        },
      };
    },
    props: {
      rowID: {
        type: String,
        default: '',
      },
      applicantBlocked: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters('keycloak', {
        getAuthData: 'getAuthData',
        getUser: 'getUser',
        getFakeUser: 'getFakeUser',
        isExpert: 'isExpert',
        isManager: 'isManager',
        // isCurator: 'isCurator',
      }),

      blockEditingInProcessComputed() {
        if (this.applicantBlocked) {
          return false;
        } /*else if (!this.applicantBlocked && !this.editionMode) {
        return false
      } */ else {
          return true;
        }
      },

      nonAspValidation() {
        // проверка для НЕ аспирантуры
        if (
          this.form.mainLastName !== '' &&
          this.form.mainFirstName !== '' &&
          this.form.mainEmail !== '' &&
          this.form.mainGender !== '' &&
          this.form.mainEdit2022IsForeign !== null /*&& this.form.mainStpType?.id !== ''*/ &&
          this.form.mainPhoneNumber !== '' &&
          this.form.mainCourse !== '' &&
          this.form.mainSpec.usl?.id !== '' &&
          this.form.mainSpec?.id !== '' &&
          this.form.mainEducationStatus?.id !== '' &&
          this.form.mainFo?.id !== '' &&
          this.form.mainFf?.id !== '' &&
          this.form.mainScoreCount !== '' &&
          this.form.mainScore5Count !== '' &&
          this.form.mainScore4Count !== '' /*&& this.form.mainSpec.usl.id !== 6*/
        ) {
          return true;
        } else {
          return false;
        }
      },

      scoreCountValidation() {
        if (
          /*this.form.mainSpec.usl.id !== 6 &&*/ Math.floor(this.form.mainScoreCount) >=
            Math.floor(this.form.mainScore5Count) &&
          Math.floor(this.form.mainScoreCount) >= Math.floor(this.form.mainScore4Count) &&
          // && Math.floor(this.form.mainScore5Count) >= Math.floor(this.form.mainScore4Count)
          // && Math.floor(this.form.mainScore5Count) >= Math.floor(this.form.mainScoreCount) / 2
          Math.floor(this.form.mainScoreCount) ===
            Math.floor(this.form.mainScore4Count) + Math.floor(this.form.mainScore5Count) &&
          Math.floor(this.form.mainScoreCount) > 0 &&
          this.academicDebt === 1
        ) {
          return true;
        } else {
          return false;
        }
      },

      prevFioComputed() {
        if (
          this.prevFio &&
          !this.form.mainEdit2022OldLastName &&
          !this.form.mainEdit2022OldFirstName &&
          !this.form.mainEdit2022OldSecondName
        ) {
          return false;
        } else if (!this.prevFio) {
          return true;
        } else {
          return true;
        }
      },

      errorText() {
        if (
          !this.scoreCountValidation &&
          Math.floor(this.form.mainScoreCount) <
            Math.floor(this.form.mainScore4Count) + Math.floor(this.form.mainScore5Count)
        ) {
          return 'Количество оценок указано неверно';
        } else if (
          !this.scoreCountValidation ||
          this.form.mainAspScoreForeignLang === 3 ||
          this.form.mainAspScorePhilosophy === 3 ||
          this.form.mainAspScoreSpeciality === 3 ||
          this.academicDebt !== 1
        ) {
          return 'Успеваемость не соответствует необходимым критериям получения данной стипендии';
        } else if (
          this.prevFio &&
          !this.form.mainEdit2022OldLastName &&
          !this.form.mainEdit2022OldFirstName &&
          !this.form.mainEdit2022OldSecondName
        ) {
          return 'Заполните прежние ФИО';
        } else {
          return 'Требуется заполнить все обязательные поля';
        }
      },

      errorWidth() {
        if (
          !this.scoreCountValidation &&
          Math.floor(this.form.mainScoreCount) <
            Math.floor(this.form.mainScore4Count) + Math.floor(this.form.mainScore5Count)
        ) {
          return '410px';
        } else if (
          !this.scoreCountValidation ||
          this.form.mainAspScoreForeignLang === 3 ||
          this.form.mainAspScorePhilosophy === 3 ||
          this.form.mainAspScoreSpeciality === 3 ||
          this.academicDebt !== 1
        ) {
          return '910px';
        } else if (
          this.prevFio &&
          !this.form.mainEdit2022OldLastName &&
          !this.form.mainEdit2022OldFirstName &&
          !this.form.mainEdit2022OldSecondName
        ) {
          return '295px';
        } else {
          return '490px';
        }
      },
    },

    created() {
      if (this.rowID !== '0') {
        this.getRow();
        this.blockOpened = false;
      } else {
        this.editionMode = true;
        this.blockOpened = true;
      }

      this.getSprFO();
      this.getSprFF();
      this.getSprUsl();

      if (this.isExpert) {
        this.blockOpened = true;
      }
    },

    methods: {
      // ...mapActions('keycloak', {setLoadingStatus: 'setLoadingStatus'}),

      async sendInfo() {
        let url = `/api/2022/korolev/add?orgId=${
          this.getUser.orgID ? this.getUser.orgID : this.getFakeUser.id
        }`;
        if (this.form.id !== 0) {
          url = `/api/2022/korolev/update?orgId=${
            this.getUser.orgID ? this.getUser.orgID : this.getFakeUser.id
          }`;
        }
        if (
          (this.aspValidation || this.nonAspValidation) &&
          this.scoreCountValidation &&
          this.prevFioComputed
        ) {
          try {
            const res = await axios.post(
              url,
              {
                id: this.form.id !== 0 ? this.form.id : '',
                mainOrg: { id: this.getUser.orgID ? this.getUser.orgID : this.getFakeUser.id },
                mainLastName: this.form.mainLastName,
                mainFirstName: this.form.mainFirstName,
                mainSecondName: this.form.mainSecondName,
                mainEdit2022OldFirstName: this.prevFio ? this.form.mainEdit2022OldFirstName : '',
                mainEdit2022OldSecondName: this.prevFio ? this.form.mainEdit2022OldSecondName : '',
                mainEdit2022OldLastName: this.prevFio ? this.form.mainEdit2022OldLastName : '',
                mainEmail: this.form.mainEmail,
                mainPhoneNumber: this.form.mainPhoneNumber,
                mainCourse: this.form.mainCourse, // Курс от 1 до 6 селектором
                mainEducationStatus: { id: this.form.mainEducationStatus.id }, // Статус обучающегося Студент/Слушатель/Курсант/Аспирант
                mainGender: this.form.mainGender, // Пол по справочнику
                mainFo: { id: this.form.mainFo.id }, // Очная, очно-заочная, заочная из справочника Fo
                mainFf: { id: this.form.mainFf.id }, // Федеральный бюджет / платное из справочника Ff
                mainSpec: { id: this.form.mainSpec.id }, // Справочник специальностей
                mainScoreCount:
                  /*this.form.mainSpec.usl.id !== 6 ?*/ this.form.mainScoreCount /*: 0*/, // Всего оценок
                mainScore5Count:
                  /*this.form.mainSpec.usl.id !== 6 ?*/ this.form.mainScore5Count /*: 0*/, // Всего 5
                mainScore4Count:
                  /*this.form.mainSpec.usl.id !== 6 ?*/ this.form.mainScore4Count /*: 0*/, // Всего 4
                mainEdit2022IsForeign: this.form.mainEdit2022IsForeign, // Является ли студент иностранцем
                mainSpecAsp: { id: 0 }, // Справочник специальностей только аспирантура (там есть особенности, но сделаем позже)
                mainScorePassCount: 0, // Оценок "Зачтено" (Для аспирантов)
                mainAspDisserTheme:
                  /*this.form.mainSpec.usl.id === 6 ? this.form.mainAspDisserTheme :*/ '', // Тема диссертации (Для аспирантов)
                mainAspWorkDoneVolume: /*this.form.mainSpec.usl.id === 6 ? this.form.mainAspWorkDoneVolume :*/ 0, // Процент дисертации (Для аспирантов)
                mainAspScoreForeignLang: /*this.form.mainSpec.usl.id === 6 ? this.form.mainAspScoreForeignLang :*/ 0, // Оценка по инязу (Для аспирантов)
                mainAspScorePhilosophy: /*this.form.mainSpec.usl.id === 6 ? this.form.mainAspScorePhilosophy :*/ 0, // Оценка по философии (Для аспирантов)
                mainAspScoreSpeciality: /*this.form.mainSpec.usl.id === 6 ? this.form.mainAspScoreSpeciality :*/ 0, // Оценка по спецпредмету (Для аспирантов)
              },
              { headers: { Authorization: `Bearer   ${this.getAuthData.token}` } },
            );
            if (res.status === 200 && res.data) {
              // меняем путь на новосозданную строку, если она создавалась
              let ID = res.data.id;

              if (!this.prevFio) {
                this.form.mainEdit2022OldLastName = null;
                this.form.mainEdit2022OldFirstName = null;
                this.form.mainEdit2022OldSecondName = null;
              }

              this.$emit('update:rowID', `${ID}`); // для block1?
              this.$emit('getAll');

              if (this.rowID === '0') {
                console.log('addRow успех');
                // router: 'justCreated' - параметр для определения только что созданного файла, для возврата из него
                // нужно вызывать this.$router.go(-2) вместо this.$router.back();
                await this.$router.push({
                  name: 'ApplicantFormKorolev',
                  params: { rowID: `${ID}`, router: 'justCreated' },
                });
                await this.getRow();
              } else {
                console.log('updateRow успех');
              }
              this.editionMode = false;
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          this.invalidData = true;
        }
      },

      async getRow() {
        this.loading = true;
        try {
          const res = await axios.get(`/api/2022/korolev/getById?rowId=${this.rowID}`, {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          if (res.status === 200 && res.data) {
            console.log('getRow успех');
            this.form = res.data;
            if (this.form.mainAspScoreSpeciality === 0) {
              this.form.mainAspScoreSpeciality = '';
            }
            if (this.form.mainAspScorePhilosophy === 0) {
              this.form.mainAspScorePhilosophy = '';
            }
            if (this.form.mainAspScoreForeignLang === 0) {
              this.form.mainAspScoreForeignLang = '';
            }
            if (this.form.mainAspWorkDoneVolume === 0) {
              this.form.mainAspWorkDoneVolume = '';
            }
            if (this.form.mainAspWorkDoneVolume === null) {
              this.form.mainAspWorkDoneVolume = '';
            }

            if (
              this.form.mainEdit2022OldLastName ||
              this.form.mainEdit2022OldFirstName ||
              this.form.mainEdit2022OldSecondName
            ) {
              this.prevFio = true;
            }

            this.academicDebt = 1;

            await this.getSprSpec('initial');

            this.form.mainSpec = res.data[0].mainSpec;
            this.form.mainEducationStatus = res.data[0].mainEducationStatus;

            this.loading = false;
          }
        } catch (e) {
          this.loading = false;
          console.log(e);
        }
      },

      async getSprFF() {
        this.spr.ff = [];
        try {
          const res = await axios.get(`/api/cms/spr/ff/get14`, {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          if (res.status === 200 && res.data) {
            for (let i = 0; i < res.data.length; i++) {
              this.spr.ff.push({
                text: res.data[i].name,
                value: res.data[i].id,
              });
            }

            this.form.mainFf.id = null;
            console.log('getSprFF успех');
          }
        } catch (e) {
          console.log(e);
        }
      },

      async getSprFO() {
        this.spr.fo = [];
        try {
          const res = await axios.get(`/api/cms/spr/fo/get`, {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          if (res.status === 200 && res.data) {
            for (let i = 0; i < res.data.length; i++) {
              this.spr.fo.push({
                text: res.data[i].name,
                value: res.data[i].id,
              });
            }

            this.spr.fo = this.spr.fo.filter((el) => el.value === 1); // только очная

            this.form.mainFo.id = null;
            console.log('getSprFO успех');
          }
        } catch (e) {
          console.log(e);
        }
      },

      async getSprSpec(arg) {
        try {
          const res = await axios.get(
            `/api/cms/spr/spec/byKodSpec/get/korolev/byUslId?uslId=${this.form.mainSpec.usl.id}`,
            { headers: { Authorization: `Bearer   ${this.getAuthData.token}` } },
          );
          if (res.status === 200 && res.data) {
            //reduce
            this.spr.spec = [];

            if (arg === 'edit') {
              this.form.mainSpec = { id: '', usl: this.form.mainSpec.usl };
              this.form.mainEducationStatus = { id: '' };
            }

            if (this.form.mainSpec.usl.id === 1 || this.form.mainSpec.usl.id === 2) {
              this.coursesArrayFiltered = this.coursesArray.filter((el) => el.value !== 1);
            } else {
              this.coursesArrayFiltered = this.coursesArray;
            }

            let sortedData = res.data.sort(
              (a, b) => a.kodSpec.replace(/[.]/g, '') - b.kodSpec.replace(/[.]/g, ''),
            );
            for (let i = 0; i < sortedData.length; i++) {
              //regexp
              this.spr.spec.push({
                text: `${sortedData[i].kodSpec} - ${sortedData[i].name}`, //${res.data[i].usl.name},
                value: sortedData[i].id,
              });
            }
            await this.getSprEducationStatus();
            console.log('getSprSpec успех');
          }
        } catch (e) {
          console.log(e);
        }
      },

      async getSprUsl() {
        try {
          const res = await axios.get(`/api/cms/spr/usl/get`, {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          if (res.status === 200 && res.data) {
            for (let i = 0; i < res.data.length; i++) {
              // if (res.data[i].id !== 6) {
              this.spr.usl.push({
                text: res.data[i].name,
                value: res.data[i].id,
              });
              // }
            }
            console.log('getSprUsl успех');
          }
        } catch (e) {
          console.log(e);
        }
      },

      async getSprEducationStatus() {
        try {
          const res = await axios.get(`/api/cms/spr/educationStatus/get`, {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          if (res.status === 200 && res.data) {
            for (let i = 0; i < res.data.length; i++) {
              this.spr.educationStatus.push({
                text: res.data[i].educationStatus,
                value: res.data[i].id,
              });
            }

            if (this.form.mainSpec.usl.id === 6) {
              // отсев аспиранта и адъютанта для аспирантуры
              this.spr.educationStatus = this.spr.educationStatus.filter(
                (el) => el.value === 4 || el.value === 5,
              );
            } else if (
              this.form.mainSpec.usl.id === 1 ||
              this.form.mainSpec.usl.id === 2 ||
              this.form.mainSpec.usl.id === 3
            ) {
              this.spr.educationStatus = this.spr.educationStatus.filter(
                (el) => el.value === 1 || el.value === 2 || el.value === 3,
              );
            }
            console.log('getSprEducationStatus успех');
          }
        } catch (e) {
          console.log(e);
        }
      },
    },
  };
</script>

<style scoped></style>
